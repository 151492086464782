<template>
  <div>
    <div class="flex items-center justify-start bg-agrogo-green-3 h-8 px-6">
      <a
        class="flex items-center font-dinpro font-medium text-agrogo-yellow-1 text-base"
        ><span class="leading-none"></span>Producción Minera</a
      >
    </div>
    <div class="table-container">
      <table class="table-auto table-cebra-detail" aria-describedby="">
        <thead>
          <tr>
            <th></th>
            <th>Cantidad (kg/año)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ record?.name }}</td>
            <td>{{ record?.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="css">
.content-component {
  display: flex;
  justify-content: space-between;
}

.table-container {
  max-width: 100%;
  overflow-x: scroll;
}
/* Tamaño del scroll */
.table-container::-webkit-scrollbar {
  width: 37px;
  height: 5px;
}
/* Estilos barra (thumb) de scroll */
.table-container::-webkit-scrollbar-thumb {
  background: #f5ce3e !important;
  border-radius: 11px;
}

.table-container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #f5ce3e !important;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.table-cebra-detail {
  width: 100%;
  border: 0;
}

.table-cebra-detail thead {
  background-color: #ffff;
}

.table-cebra-detail thead tr th {
  width: 100px;
}

.table-cebra-detail th:first-child {
  text-align: left !important;
}

.table-cebra-detail tbody tr {
  background: #e5e5e5 !important;
  color: #5d5d5d;
}

.table-cebra-detail tbody tr:nth-child(2n) {
  background-color: #ffff !important;
}

.table-cebra-detail th {
  color: #5d5d5d !important;
  font-size: 14px;
  font-family: "Nexa", sans-serif;
  font-weight: 600;
  text-align: center !important;
}

.table-cebra-detail th,
.table-cebra-detail td {
  padding: 0.5rem;
  text-align: center;
}

.table-cebra-detail td:first-child {
  text-align: left !important;
}

.table-cebra-detail thead tr th:first-child {
  width: 200px;
}

.input-table {
  background: #153e35 !important;
}

/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .content-component {
    flex-direction: column;
  }
}
</style>
