<template>
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else>
    <div>
      <!-- RECOMENDACIONES -->
      <div
        class="flex items-center justify-start px-8 h-7 bg-agrogo-green-3 md:mx-16 lg:mx-24 xl:mx-40 md:h-14 md:mt-11 md:pl-16"
      >
        <a class="font-nexa text-white text-sm md:text-2xl"
          ><span
            class="icon-img text-agrogo-yellow-1 text-sm pr-2.5 leading-none md:text-xl"
          ></span
          >Recomendaciones</a
        >
      </div>
      <div
        class="md:flex items-center md:items-start md:px-5 md:py-6 md:mx-16 lg:mx-24 xl:mx-40"
      >
        <div
          class="flex flex-col px-4 pt-3 md:pt-0 lg:pt-0 h-auto w-auto space-y-3 md:space-y-0 md:w-1/2"
        >
          <div class="flex items-center md:px-8 lg:px-3 md:items-start">
            <a
              class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
            ></a>
            <a
              class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
              >Se permiten máximo 10 imágenes (en formato jpg ó jpeg) por
              publicación.</a
            >
          </div>
          <div class="flex items-center md:px-8 lg:px-3 md:items-start">
            <a
              class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
            ></a>
            <a
              class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
              >Se permiten máximo 5 videos (formato mp4) por publicación.</a
            >
          </div>
          <div class="flex items-center md:px-8 lg:px-3 md:items-start">
            <a
              class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 lg:mt-1"
            ></a>
            <a
              class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
              >Se visualizarán los videos de primero.</a
            >
          </div>
        </div>
        <div
          class="flex flex-col px-4 pt-3 pb-5 md:pt-0 lg:pt-0 h-auto w-auto space-y-3 md:space-y-0 md:px-0 lg:px-0 md:justify-between md:w-1/2"
        >
          <div class="flex items-center md:px-8 md:items-start">
            <a
              class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
            ></a>
            <a
              class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
              >Se permite que cada vídeo sea máximo de 3 minutos, de los cuáles
              se visualizarán sólo los primeros 45 segundos.</a
            >
          </div>
          <div class="flex items-center md:px-8 md:items-start">
            <a
              class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
            ></a>
            <a
              class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
              >Tip de foto: Toma la foto de frente con buena iluminación y trata
              de no utilizar el zoom.</a
            >
          </div>
        </div>
      </div>
    </div>
    <section class="bg-white px-4 py-4 h-full md:px-20 lg:px-20 xl:px-48">
      <div class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-6">
        <slider-multimedia :records="records.slice(1, records.length)" />
        <div class="flex flex-col justify-center items-center lg:items-start">
          <div class="layout">
            <draggable
              v-model="records"
              class="grid grid-cols-3 gap-4"
              @start="drag = true"
              @end="drag = false"
              @change="changeOrder"
              item-key="id"
              :move="checkMove"
            >
              <template #item="{ element, index }">
                <card-video-or-img
                  v-if="element.type"
                  :item="element"
                  :index="index"
                  @remove="remove"
                  :isBusy="isSaveImage"
                />
                <card-file
                  v-else
                  @fileChange="fileChange"
                  :isBusy="isSaveImage"
                />
              </template>
            </draggable>
          </div>
          <div
            v-if="records.length > 1"
            class="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 mt-3"
          >
            <div class="flex items-center justify-start px-2">
              <a class="icon-imgmove text-lg text-agrogo-green-3 pr-2"
                ><span class="font-nexa text-agrogo-black-1 text-sm">
                  Arrastre la imagen para cambiar el orden.</span
                ></a
              >
            </div>
          </div>
          <div
            v-else
            class="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 mt-3"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  </section>
  <message-multimedia v-if="isSaveImage || isError || isSuccess" />
  <!-- CONTENEDOR 3 BOTONES NAVEGACION -->
  <div
    class="bg-agrogo-yellow-1 h-14 space-x-3 md:space-x-4 lg:space-x-4 sticky bottom-0 z-50 z-50"
    :class="{ 'h-16 lg:h-20 md:h-20': validationData }"
  >
    <div class="flex items-center justify-center pt-2">
      <button @click="previus()" type="button" class="btn-primary mr-4">
        <span
          class="icon-desplegar flex items-center transform -rotate-180 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button
        @click="next()"
        type="button"
        class="btn-primary"
        :disabled="validationData"
      >
        <div class="loader" v-if="isBusy2" />
        <div v-else class="flex">
          Siguiente
          <span
            class="icon-desplegar flex items-center transform text-agrogo-yellow-2 text-xs ml-1 leading-none"
          ></span>
        </div>
      </button>
    </div>
    <p
      v-if="validationData"
      class="pt-1 pb-1 font-nexa font-normal text-xs lg:text-base md:text-base text-agrogo-green-2 text-left flex justify-center"
    >
      Debes cargar al menos una imagen para continuar.
    </p>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import validationToken from "@/mixins/validationToken";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
import saveImageMixin from "@/mixins/saveImageMixin";
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
//components
import CardFile from "./components/CardFile";
import CardVideoOrImg from "./components/CardVideoOrImg";
import draggable from "vuedraggable";
import MessageMultimedia from "../globals/MessageMultimedia";
import SliderMultimedia from "./components/SliderMultimedia";
export default {
  inject: ["provider"],
  emits: ["saveImages"],
  mixins: [validationToken, beforeWindowMixin, saveImageMixin],
  components: {
    CardFile,
    draggable,
    CardVideoOrImg,
    MessageMultimedia,
    SliderMultimedia,
  },
  data() {
    const me = this;
    return {
      repository: me.provider.publishFarmRepository,
      repositoryEdit: me.provider.publishFarmEditRepository,
      slug: me.$route.params.slug,
      video1: null,
      img: null,
      record: {
        src: require("@/assets/images/previewimg.png"),
        type: "img",
      },
      formData: [],
      images: [],
      videos: [],
      nextStep: null,
      isBusy2: false,
      isBusy: true,
      records: [
        {
          type: null,
          fixed: true,
        },
      ],
      dirty: true,
    };
  },
  computed: {
    ...mapState("publishFarm", [
      "step",
      "multimedia",
      "farmSave",
      "dataSave",
      "isSaveImage",
    ]),
    validationData() {
      const me = this;
      let isValid = false;
      const data = [];
      me.records.map((el) => {
        if (el.type) {
          data.push(el);
        }
      });
      const image = data.find((el) => el.type == "img" || el.type == 1);
      isValid = image ? false : true;
      return isValid;
    },
  },
  methods: {
    ...mapActions("publishFarm", [
      "findStep",
      "addMultimedia",
      "updateMessage",
    ]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    next() {
      const me = this;
      if (!me.validationData) {
        me.formData = [];
        me.records.map((el) => {
          if (el.type) {
            me.formData.push(el);
          }
        });
        if (me.formData.length > 0) {
          me.saveData();
        }
      }
    },
    fileChange(e) {
      const me = this;
      // Recoger el fichero
      const files = e.target.files;
      if (files.length) {
        files.forEach((el) => {
          if (el) {
            const { type } = el;
            if (
              type != "image/jpeg" &&
              type != "image/jpg" &&
              // type != "image/png" &&
              type != "video/mp4"
            ) {
              me.$swal({
                icon: "error",
                title: "¡Formato no permitido! Solo (jpg, jpeg, mp4)",
              });
            } else {
              if (type == "video/mp4") {
                if (me.videos.length <= 4) {
                  me.videos.push(el);
                  me.loadVideo(el);
                } else {
                  me.$swal({
                    icon: "error",
                    title: "Se permiten máximo 5 videos por publicación.",
                  });
                }
              } else {
                if (me.images.length <= 9) {
                  me.images.push(el);
                  me.loadImg(el);
                } else {
                  me.$swal({
                    icon: "error",
                    title: "Se permite máximo 10 imágenes por publicación.",
                  });
                }
              }
            }
          }
        });
      }
    },
    loadVideo(file) {
      const me = this;
      // 314572800 bytes
      // if (file["size"] <= 314572800) {
      let videoCodified = URL.createObjectURL(file);
      let videoElement = document.createElement("video");
      const sourceElement = document.createElement("source");
      videoElement.id = "video-preview";
      sourceElement.src = videoCodified;
      videoElement.appendChild(sourceElement);
      videoElement.load();
      setTimeout(() => {
        const minutes = videoElement.duration / 60;
        console.log("Minutes del video", Number(minutes.toFixed(1)));
        if (Number(minutes.toFixed(1)) > 3) {
          me.$swal({
            icon: "error",
            title:
              "El tiempo máximo por vídeo es de 3 minutos, te recordamos que sólo se tomarán los primeros 45 segundos del vídeo.",
          });
          const index2 = me.videos.findIndex((y) => y == file);
          me.videos.splice(index2, 1);
        } else {
          me.setLayout({
            src: videoCodified,
            type: "video/mp4",
            srcImg: require("@/assets/images/previewvideo.png"),
            file: file,
          });
        }
      }, 500);
    },
    loadImg(file) {
      const me = this;
      // 20971520 bytes
      if (file["size"] <= 20971520) {
        const imgCodified = URL.createObjectURL(file);
        me.setLayout({
          src: imgCodified,
          type: "img",
          file: file,
        });
      } else {
        me.$swal({
          icon: "error",
          title: "Peso maxímo permitido es de 20MB",
        });
      }
    },
    setLayout(item) {
      const me = this;
      me.records.push({ ...item, fixed: false });
      const data = [];
      me.records.forEach((el) => {
        if (el.type) data.push(el);
      });
      me.addMultimedia(data);
      if (me.isSaveImage) me.abortSaveMultimedia();
    },
    remove(index, item) {
      const me = this;
      let record = null;
      me.records.find((el) => {
        if (el.type && el.src == me.record.src) {
          record = me.record;
        }
      });

      if (record) {
        me.record = {
          src: require("@/assets/images/previewimg.png"),
          type: "img",
        };
      }

      if (item.type == "video/mp4" || item.type == 2) {
        const index2 = me.videos.findIndex((y) => y.src == item.src);
        me.videos.splice(index2, 1);
      } else {
        const index2 = me.images.findIndex((y) => y.src == item.src);
        me.images.splice(index2, 1);
      }

      me.records.splice(index, 1);
      const data = [];
      me.records.map((el) => {
        if (el.type) {
          data.push(el);
        }
      });
      me.addMultimedia(data);
      me.abortSaveMultimedia();
    },
    async saveData() {
      const me = this;
      me.$emit("saveImages");
      me.findStep(me.nextStep);
      // try {
      //   me.isBusy2 = true;
      //   const id = me.farmSave.id;
      //   const dataAditional = await generateHash();
      //   let formData = new FormData();
      //   me.records.map((el, index) => {
      //     if (el.type) {
      //       let media = null;
      //       if (el.file) {
      //         media = el.file;
      //       } else {
      //         media = Object.values(el.media);
      //       }
      //       const type = el.type == "img" ? 1 : 2;
      //       const order = index;
      //       formData.append("multimedia[" + index + "][media]", media);
      //       formData.append("multimedia[" + index + "][type]", type);
      //       formData.append("multimedia[" + index + "][order]", order);
      //     }
      //   });
      //   formData.append("id", id);
      //   formData.append("apiKey", dataAditional.apiKey);
      //   formData.append("signature", dataAditional.signature);
      //   formData.append("utcTimeStamp", dataAditional.utcTimeStamp);
      //   formData.append("last_step", 6);
      //   await me.repository.uploadImage(formData);
      //   me.findStep(me.nextStep);
      // } catch (error) {
      //   const code = error.response.status;
      //   if (code == 401) {
      //     me.validationExpiredToken();
      //   }
      // } finally {
      //   me.isBusy2 = false;
      // }
    },
    checkMove(e) {
      return !e.draggedContext.element.fixed && !e.relatedContext.element.fixed;
    },
    async loadMultimedia() {
      const me = this;
      me.records = [];
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repositoryEdit.getMultimedia({
          ...dataAditional,
          property_id: me.slug,
        });
        me.records.push({
          type: null,
          fixed: true,
        });
        if (data.length > 0) {
          data.forEach((el) => {
            if (el.type == 2) {
              me.records.push({
                ...el,
                srcImg: require("@/assets/images/previewvideo.png"),
                src: `${API_ROUTES.image.get}${
                  el?.media?.tmp_url ? el?.media?.tmp_url : el.media.default_url
                }`,
              });
              me.videos.push(el);
            } else {
              me.records.push({
                ...el,
                src: `${API_ROUTES.image.get}${
                  el?.media?.tmp_url ? el?.media?.tmp_url : el.media.default_url
                }`,
              });
              me.images.push(el);
            }
          });
        }
        me.records.forEach((el) => {
          if (el.type) {
            if (el.type == 2) {
              el.type = "video/mp4";
            } else {
              el.type = "img";
            }
          }
        });
        me.addMultimedia(me.records);
        me.updateMultimediaCopy(me.records);
      } catch (error) {
        // console.log(error);
        me.records.push({
          type: null,
          fixed: true,
        });
      } finally {
        me.isBusy = false;
      }
    },
    changeOrder() {
      const me = this;
      const data = [];
      me.records.forEach((el) => {
        if (el.type) data.push(el);
      });
      me.addMultimedia(data);
      if (me.isSaveImage) me.abortSaveMultimedia();
    },
  },
  created() {
    const me = this;
    me.nextStep = me.step.number2 + 1;
    me.updateMessage(
      `¿Salir de la página? Si abandonas las fotos y videos no serán guardadas.`
    );
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    const me = this;
    me.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    if (me.multimedia.length > 0) {
      me.isBusy = false;
      me.multimedia.map((el) => {
        if (el.type) {
          if (el.type == "img") {
            me.images.push(el);
          } else {
            me.videos.push(el);
          }
        }
      });
      const data = me.multimedia.filter((el) => el.type);
      me.records = [...me.records, ...data];
    } else if (me.slug) {
      await me.loadMultimedia();
    } else {
      me.isBusy = false;
    }
  },
};
</script>
<style lang="css">
.layout {
  min-height: 250px;
  max-width: 500px;
}
.panel-img-video {
  height: 16rem;
  overflow: hidden;
  border-radius: 0.75rem !important;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  width: 100%;
  height: 64px;
  position: absolute;
  z-index: 2;
}
@media (min-width: 768px) {
  .wrapper {
    height: 5.5rem;
  }
}
</style>
